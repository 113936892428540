import { Component, ViewChild, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { ModificacionesForecast } from '../../../objects/modificaciones-forecast-object';

@Component({
  selector: 'app-grid-modificaciones-forecast',
  templateUrl: './grid-modificaciones-forecast.component.html',
  styleUrls: ['./grid-modificaciones-forecast.component.css']
})
export class GridModificacionesForecastComponent implements OnInit, OnChanges {

  constructor() { }
  @ViewChild('myGridAlertas', { static: false }) myGridAlertas: jqxGridComponent;
  @Output("ViewClick") ViewClick = new EventEmitter();
  @Input('data') data: Array<ModificacionesForecast> = [];

  ngOnInit() {}

  ngOnChanges() {
    this.source.localdata = this.data;
    this.dataAdapter.dataBind();
  }

  CellClick(event: any) {
    let rowData = event.args.row.bounddata;
    let dataField = event.args.datafield;

    if (dataField == GridModificacionesForecastColumns.DetalleClick) this.DetalleClick(rowData);
    //else if (dataField == GridRequisasCreadasColumns.AgregarSkuRequisasClick) this.AgregarSkuRequisasClick(rowData);
  }

  //Boton para priorizar sku
  DetalleCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="30" width="30" src="../../../../assets/icons/file.png"></div>'
  }

  DetalleClick(rowData: ModificacionesForecast) {
    this.ViewClick.emit(rowData);
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'string' },
        { name: 'Timer', type: 'string' },
        { name: 'Referencia', type: 'string' },
        { name: 'Tipo', type: 'string' },
        { name: 'Fecha', type: 'string' },
        { name: 'Sku', type: 'number' },
        { name: 'Descripcion', type: 'string', },
        { name: 'Categoria', type: 'string' },
        { name: 'Planta', type: 'string' },
        { name: 'Unidad', type: 'string' },
        { name: 'Cantidad', type: 'number' },
        { name: 'Observacion', type: 'string' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      //{ text: 'Id', datafield: 'Id', hidden: true },
      { text: 'Timer', datafield: 'Timer', cellsalign: 'center', align: 'center', filterable: false, pinned: true, width: '8%' },
      { text: 'Referencia', datafield: 'Referencia', cellsalign: 'center', align: 'center', pinned: true, width: '10%' },
      { text: 'Tipo', datafield: 'Tipo', cellsalign: 'center', align: 'center', pinned: true, width: '12%' },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Sku', datafield: 'Sku', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Descripción', datafield: 'Descripcion', cellsalign: 'center', align: 'center', width: '28%' },
      { text: 'Categoria', datafield: 'Categoria', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Planta', datafield: 'Planta', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Unidad', datafield: 'Unidad', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Observación', datafield: 'Observacion', cellsalign: 'center', align: 'center', filterable: false, width: '12%' },
      { text: "Detalle", datafield: "DetalleClick", cellsalign: 'center', align: "center", filterable: false, width: '8%', cellsrenderer: this.DetalleCellRenderer }
    ];
}

enum GridModificacionesForecastColumns {
  DetalleClick = 'DetalleClick'
}
