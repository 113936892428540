import { Component, OnInit, ViewChild } from '@angular/core';
import * as Enumerable from 'linq';
import * as moment from "moment";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexYAxis,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexAnnotations
} from "ng-apexcharts";


@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css']
})
export class GraficoComponent implements OnInit {

  constructor() { }

  //ngOnInit() {}
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  //Fecha: number = 1538778600000;
  //Fecha: string = "2018-10-05 16:30:00";

  DataSimulada: DataSimulada[] = [
    {
      Fecha: "2021-02-04 14:09:37.2916780",
      Open: 6629.81,
      High: 6650.5,
      Low: 6623.04,
      Close: 6633.33
    },
    {
      Fecha: "2021-02-06 14:09:37.2916780",
      Open: 6632.01,
      High: 6643.59,
      Low: 6620,
      Close: 6630.11
    },
    {
      Fecha: "2021-02-08 14:09:37.2916780",
      Open: 6627,
      High: 6700.62,
      Low: 6584.22,
      Close: 6603.02
    },
    {
      Fecha: "2021-02-09 14:30:37.2916780",
      Open: 6727,
      High: 6800.62,
      Low: 6484.22,
      Close: 6503.02
    },
    {
      Fecha: "2021-08-10 14:30:37.2916780",
      Open: 6727,
      High: 6800.62,
      Low: 6484.22,
      Close: 6503.02
    },
    {
      Fecha: "2021-09-11 14:30:37.2916780",
      Open: 6629.81,
      High: 6650.5,
      Low: 6623.04,
      Close: 6633.33
    }
  ];

  DataSimuladaLine: DataSimuladaLine[] = [
    {
      Fecha: "2021-01-13 14:09:37.2916780",
      Valor: 6330
    },
    {
      Fecha: "2021-08-15 11:15:09:37.2916780",
      Valor: 6545
    },
    {
      Fecha: "2021-11-04 18:09:37.2916780",
      Valor: 6880
    },
    {
      Fecha: "2021-07-28 18:09:37.2916780",
      Valor: 6880
    },
    {
      Fecha: "2021-07-28 18:09:37.2916780",
      Valor: 6880
    },
    {
      Fecha: "2021-09-25 18:09:37.2916780",
      Valor: 6880
    }
  ]

  DataPush: Array<DataGrafico> = [];
  DataPushLine: Array<DataLine> = [];

  ngOnInit() {
    //Fecha string;
    //let b: number;
    //b = Date.parse(this.Fecha);
    //console.log(b);

    //let c = new Date(b);
    //console.log(c)

    //Fecha number;
    //let b: Date;
    //let d: string;
    //b = new Date(this.Fecha);
    //d = b.toString()
    //console.log(b);

    //let c: number;
    //c = Date.parse(d);
    //console.log(c);
    let x: any;
    x= new Date('28 Jul 2021').getTime();
    console.log(x);

    let y: any;
    y = new Date(x);
    console.log(y);

    this.DataSimulada.forEach(f => {
      let barra: Array<number> = [];
      barra.push(f.Open, f.High, f.Low, f.Close);
      let FechaNumero: number;
      FechaNumero = Date.parse(f.Fecha);
      let obj = {
        x: new Date(FechaNumero),
        y: barra
      }
      this.DataPush.push(obj);
      console.log(this.DataPush)
    });

    this.DataSimuladaLine.forEach(f => {
      let FechaNumero: number;
      FechaNumero = Date.parse(f.Fecha);
      let obj = {
        x: new Date(FechaNumero),
        y: f.Valor
      }
      this.DataPushLine.push(obj);      
      console.log(this.DataPushLine)
    });

    this.chartOptions = {
      series: [
        {
          name: "line",
          type: "line",
          data:
            Enumerable.from(this.DataPushLine).orderBy(o => o.x).toArray()
          //  [
          //  {
          //    x: new Date(1538778600000),
          //    y: 6604
          //  },
          //  {
          //    x: new Date(1538782200000),
          //    y: 6602
          //  },
          //  {
          //    x: new Date(1538814600000),
          //    y: 6607
          //  },
          //  {
          //    x: new Date(1538884800000),
          //    y: 6620
          //  }
          //]
        },
        {
          name: "candle",
          type: "candlestick",
          data:
            Enumerable.from(this.DataPush).orderBy(o => o.x).toArray()
          //  [
          //  {
          //    x: new Date(1538778600000),
          //    y: [6629.81, 6650.5, 6623.04, 6633.33]
          //  },
          //  {
          //    x: new Date(1538780400000),
          //    y: [6632.01, 6643.59, 6620, 6630.11]
          //  },
          //  {
          //    x: new Date(1538782200000),
          //    y: [6630.71, 6648.95, 6623.34, 6635.65]
          //  },
          //  {
          //    x: new Date(1538784000000),
          //    y: [6635.65, 6651, 6629.67, 6638.24]
          //  },
          //  {
          //    x: new Date(1538785800000),
          //    y: [6638.24, 6640, 6620, 6624.47]
          //  },
          //  {
          //    x: new Date(1538787600000),
          //    y: [6624.53, 6636.03, 6621.68, 6624.31]
          //  },
          //  {
          //    x: new Date(1538789400000),
          //    y: [6624.61, 6632.2, 6617, 6626.02]
          //  },
          //  {
          //    x: new Date(1538791200000),
          //    y: [6627, 6627.62, 6584.22, 6603.02]
          //  },
          //  {
          //    x: new Date(1538793000000),
          //    y: [6605, 6608.03, 6598.95, 6604.01]
          //  },
          //  {
          //    x: new Date(1538794800000),
          //    y: [6604.5, 6614.4, 6602.26, 6608.02]
          //  }            
          //]
        }
      ],
      chart: {
        height: 300,
        type: "line"
      },
      stroke: {
        width: [1, 1]
      },
      tooltip: {
        shared: true,
        custom: [
          function ({ seriesIndex, dataPointIndex, w }) {
            return w.globals.series[seriesIndex][dataPointIndex];
          },
          function ({ seriesIndex, dataPointIndex, w }) {
            const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
            const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
            const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex];
            const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex];
            return (
              '<div class="apexcharts-tooltip-candlestick">' +
              '<div>Open: <span class="value">' +
              o +
              "</span></div>" +
              '<div>High: <span class="value">' +
              h +
              "</span></div>" +
              '<div>Low: <span class="value">' +
              l +
              "</span></div>" +
              '<div>Close: <span class="value">' +
              c +
              "</span></div>" +
              "</div>"
            );
          }
        ]
      },
      xaxis: {
        type: "category",
        labels: {
          formatter: function(val) { return moment(val).format("DD/MM/YYYY"); }
        }
       
      },
      annotations: {
        xaxis: [
          {
            x: new Date('28 Jul 2021').getTime(),
            x2: new Date('28 Jul 2021').getTime(),
            fillColor: '#B3F7CA',
            label: {
              text: 'X-axis range'
            }
          }
        ]
      }
    };
  }
  
   

}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  annotations: ApexAnnotations;
  stroke: any; //ApexStroke
};

export interface DataSimulada {
  Fecha: string;
  Open: number;
  High: number;
  Low: number;
  Close: number; 
}

export interface DataGrafico {
  x: Date;
  y: Array<number>;
}

export interface DataSimuladaLine {
  Fecha: string;
  Valor: number;
}

export interface DataLine {
  x: Date;
  y: number;
}
