import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrosku'
})
export class FiltroskuPipe implements PipeTransform {

  //transform(value: any, ...args: any[]): any {
  //  return null;
  //}

  transform(snapshotArr: any, searchValue: string) {
    if (!snapshotArr) {
      return [];
    }
    if (!searchValue) {
      return snapshotArr;
    }
    if (snapshotArr && searchValue) {
      return snapshotArr.filter(snapshot => {
        return snapshot.Sku.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
  }

}
