import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { Gestiones } from '../../../objects/modificaciones-forecast-object';

@Component({
  selector: 'app-grid-gestiones',
  templateUrl: './grid-gestiones.component.html',
  styleUrls: ['./grid-gestiones.component.css']
})
export class GridGestionesComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridGestiones', { static: false }) myGridGestiones: jqxGridComponent;
  @Input('data') data: Array<Gestiones> = [];

  ngOnInit() {
    this.source.localdata = this.data;
    this.dataAdapter.dataBind();
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'string' },
        { name: 'ReferenciaGestion', type: 'string' },
        { name: 'FechaETD', type: 'string' },
        { name: 'Unidad', type: 'string' },
        { name: 'Cantidad', type: 'string' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Referencia Gestión', datafield: 'ReferenciaGestion', cellsalign: 'center', align: 'center', width: '30%' },
      { text: 'Fecha ETD', datafield: 'FechaETD', cellsalign: 'center', align: 'center', filterable: false, width: '20%' },
      { text: 'Unidad', datafield: 'Unidad', cellsalign: 'center', align: 'center', filterable: false, width: '25%' },
      { text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '25%' }
      //{ text: 'Fecha Inicio', datafield: 'FechaInicio', cellsalign: 'center', align: 'center', cellsformat: 'dd/MM/yyyy hh:mm:ss', filterable: false, width: '15%' }
    ];

}
