import { Component, OnInit, ViewChild } from '@angular/core';
import { GridVisualizarPedidosComponent } from '../grid-visualizar-pedidos/grid-visualizar-pedidos.component';
import * as Enumerable from 'linq';
import * as moment from "moment";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {

  constructor() { }
  @ViewChild(GridVisualizarPedidosComponent, { static: false }) myGridVisualizarPedidos: GridVisualizarPedidosComponent;

  ngOnInit() {
  }

  Test: Array<test> = [];
  MostrarPedido: boolean = false;
  //arrayBuffer: any;
  file: File;

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let listaDatos: Array<test> = [];
    let inc = 1

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.file;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        listaDatos = XLSX.utils.sheet_to_json(sheet);
        this.Test = Enumerable.from(listaDatos).select(s => {
          return {
            Id: inc++,
            Fecha: moment(s.Fecha).format("YYYY-MM-DD"),
            Descripcion: s.Descripcion,
            Ruta: s.Ruta,
            Cod_Producto: s.Cod_Producto,
            CantidadEnPiezas: s.CantidadEnPiezas,
            Ajuste_1_Dia: s.Ajuste_1_Dia
          }
        }).toArray();
        this.myGridVisualizarPedidos.source.localdata = this.Test;
        this.myGridVisualizarPedidos.dataAdapter.dataBind();
        this.MostrarPedido = true;        
      }, {});
    }
    reader.readAsBinaryString(file);

    //let fileReader = new FileReader();
    //fileReader.onload = (e) => {
    //  this.arrayBuffer = fileReader.result;
    //  var data = new Uint8Array(this.arrayBuffer);
    //  var arr = new Array();
    //  for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    //  var bstr = arr.join("");
    //  var workbook = XLSX.read(bstr, { type: "binary" });
    //  var first_sheet_name = workbook.SheetNames[0];
    //  var worksheet = workbook.Sheets[first_sheet_name];
    //  console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
    //  listaDatos = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    //  this.Test = Enumerable.from(listaDatos).select(s => {
    //    return {
    //      Fecha: moment(s.Fecha).format("YYYY-MM-DD"),
    //      Ruta: s.Ruta,
    //      Cod_Producto: s.Cod_Producto,
    //      CantidadEnPiezas: s.CantidadEnPiezas,
    //      Ajuste_1_Dia: s.Ajuste_1_Dia
    //    }
    //  }).toArray();
    //}
    //fileReader.readAsArrayBuffer(this.file);
  }

  //willDownload = false;

  //onFileChange(ev) {
  //  let workBook = null;
  //  let jsonData = null;
  //  const reader = new FileReader();
  //  const file = ev.target.files[0];
  //  reader.onload = (event) => {
  //    const data = reader.result;
  //    workBook = XLSX.read(data, { type: 'binary' });
  //    jsonData = workBook.SheetNames.reduce((initial, name) => {
  //      const sheet = workBook.Sheets[name];
  //      initial[name] = XLSX.utils.sheet_to_json(sheet);
  //      return initial;
  //    }, {});
  //    const dataString = JSON.stringify(jsonData);
  //    document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
  //    this.setDownload(dataString);
  //  }
  //  reader.readAsBinaryString(file);
  //}

  //setDownload(data) {
  //  setTimeout(() => {
  //    const el = document.querySelector("#download");
  //    el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
  //    el.setAttribute("download", 'xlsxtojson.json');
  //  }, 1000)
  //}

}

export interface test {
  Fecha: string;
  Descripcion: string;
  Ruta: number;
  Cod_Producto: number;
  CantidadEnPiezas: number;
  Ajuste_1_Dia: number;
}

//export interface testList {
//  Fecha: number;
//  Descripcion: string;
//  Ruta: number;
//  Cod_Producto: number;
//  CantidadEnPiezas: number;
//  Ajuste_1_Dia: number;
//}
