import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import * as Enumerable from 'linq';
//require("../../../extensions/moment-extends");

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css']
})
export class GraficoComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }
   
  ngAfterViewInit() { }

  

  //data: any[] = [
  //  { 'month': 1, 'min': -1.9, 'max': 3.7, 'avg': -1.9, 'valid': 0, 'iqual': 0 },
  //  { 'month': 2, 'min': 3.7, 'max': 8, 'avg': -0.9, 'valid': 0, 'iqual': 0 },
  //  { 'month': 3, 'min': 0.8, 'max': 9.8, 'avg': 4.9, 'valid': 0, 'iqual': 0 },
  //  { 'month': 4, 'min': 4.1, 'max': 13.9, 'avg': 8.7, 'valid': 0, 'iqual': 0 },
  //  {
  //    'month': 5, 'min': 8.0, 'max': 18.4, 'avg': 13.1, 'valid': 1, 'iqual': 1
  //  },
  //  { 'month': 6, 'min': 11.5, 'max': 25.8, 'avg': 13.1, 'valid': 0, 'iqual': 1 },
  //  { 'month': 7, 'min': 11.3, 'max': 22.2, 'avg': 16.6, 'valid': 0, 'iqual': 0 },
  //  { 'month': 8, 'min': 13.3, 'max': 25.3, 'avg': 18.4, 'valid': 0, 'iqual': 0 },
  //  { 'month': 9, 'min': 13.0, 'max': 24.4, 'avg': 17.6, 'valid': 0, 'iqual': 0 },
  //  { 'month': 10, 'min': 10.3, 'max': 20.8, 'avg': 14.3, 'valid': 0, 'iqual': 0 },
  //  { 'month': 11, 'min': 6.6, 'max': 14.9, 'avg': 9.2, 'valid': 0, 'iqual': 0 },
  //  { 'month': 12, 'min': 2.1, 'max': 8.4, 'avg': 4.2, 'valid': 0, 'iqual': 0 },
  //  { 'month': 13, 'min': -0.5, 'max': 4.5, 'avg': 1.5, 'valid': 0, 'iqual': 0 }
  //];

  //toolTipCustomFormatFn = (value: any, itemIndex: any, serie: any, group: any, categoryValue: any, categoryAxis: any): string => {
  //  let dataItem = this.data[itemIndex];
  //  return '<DIV style="text-align:left"><b>Month: ' +
  //    categoryValue + '</b><br />Min: ' +
  //    dataItem.min + '°<br />Max: ' +
  //    dataItem.max //+ '°<br />Average: ' +
  //    //dataItem.avg + '°</DIV>';
  //};

  //month: string;

  //bands() {
  //  let bands = [];

  //  for (var i = 0; i < this.data.length; i++) {
  //    for (var j = i + 1; j < this.data.length; j++) {
  //      if (this.data[i].month == this.data[j].month) {
  //        this.month = this.data[j].month;
  //        let obj = {
  //          from: this.data[j].iqual, to: this.data[j].iqual
  //        }
  //        bands.push({ minValue: obj.from, maxValue: obj.to, fillColor: 'red', opacity: 0.2 });

  //      }
  //    }
  //  }

  //  //for (var i = 0; i < this.data.length; i++) {
  //  //  if (this.data[i].iqual == 1) {
  //  //    let obj = {
  //  //      from: this.data[i].iqual, to: this.data[i].iqual
  //  //    }
  //  //    bands.push({ minValue: obj.from, maxValue: obj.to, fillColor: '#0000FF', opacity: 0.1 });
  //  //  }
  //  //}       

  //  //for (let i = 0; i < recessions.length; i++) {
  //  //  bands.push({ minValue: recessions[i].from, maxValue: recessions[i].to, fillColor: '#0000FF', opacity: 0.1 });
  //  //}
  //  console.log(bands);
  //  return bands;
  //  //this.data;
  //  //let bands = [];
  //  //for (var i = 0; i < this.data.length; i++) {
  //  //  for (var j = i + 1; j < this.data.length; j++) {
  //  //    if (this.data[i].month == this.data[j].month) {
  //  //      this.month = this.data[j].month;
  //  //      bands.push({ minValue: parseInt(this.month), maxValue: parseInt(this.month), fillColor: 'red', opacity: 0.2 });
  //  //      //this.month = this.data[j].month;
  //  //    }
  //  //  }
  //  //}
    
  //  //return bands;
  //}

  //padding = { left: 5, top: 5, right: 5, bottom: 5 };

  //titlePadding = { left: 90, top: 0, right: 0, bottom: 10 };

  //xAxis =
  //  {
  //    dataField: 'month',
  //    unitInterval: 1,
  //    tickMarks: {
  //      visible: true,
  //      interval: 1,
  //      color: '#020206'
  //    },
  //    gridLines: {
  //      visible: true,
  //      interval: 1
  //    },
  //    labels: { angle: -80 },      
  //    valuesOnTicks: true,
  //    //bands: this.bands()
  //  };

  //valueAxis =
  //  {
  //    minValue: -5,
  //    maxValue: 30,
  //    unitInterval: 5,
  //    title: { text: 'Temperature [C]<br>' },
  //    labels: {
  //      horizontalAlignment: 'right',
  //      formatSettings: { sufix: '°' }
  //    },
  //    gridLines: {
  //      visible: false
  //    }     
  //  };

  //seriesGroups =
  //  [
  //    {
  //      type: 'rangecolumn',
  //      toolTipFormatFunction: this.toolTipCustomFormatFn,
  //      series: [
  //        {
  //          dataFieldTo: 'max',            
  //          dataFieldFrom: 'min',
  //          //displayText: 'Temperature Range',
  //          opacity: 100,
  //          colorFunction: (value: any, itemIndex: any, serie: any, group): any => {
  //            let dataItem = this.data[itemIndex];
  //            if (dataItem != undefined) {
  //              if (dataItem.valid == 1) {
  //                return '#D30E2F' /* red */
  //              }
  //              return '#24A037' /*green*/;
  //            }              
  //          }
  //        }
  //      ]        
  //    },
  //    {
  //      type: 'line',
  //      symbolType: 'square',
  //      toolTipFormatFunction: this.toolTipCustomFormatFn,
  //      series: [
  //        {
  //          dataField: 'avg',
  //          //displayText: 'Average Temperature',
  //          opacity: 1,
  //          lineWidth: 3
  //        }
  //      ]
  //    }
  //  ];

  //TERMINA PRIMER GRAFICO DE RANGE COLUMN

  data: any[] = [
    { year: '2021/01/05', population: 490815046, avg: 490815170 },
    { year: '2021/02/14', population: 492709300, avg: 490759400 },
    { year: '2021/03/05', population: 494774599, avg: 494774620, iqual: 1 },
    { year: '2021/04/12', population: 496633373, avg: 496633493 },
    { year: '2021/05/23', population: 498408547, avg: 498408587 },
    { year: '2021/06/01', population: 500418320, avg: 500418400 },
    { year: '2021/07/07', population: 502186144, avg: 502186210 },
    { year: '2021/08/30', population: 503379305, avg: 503379350 },
    { year: '2021/09/01', population: 504961522, avg: 504961572 },
    { year: '2021/09/02', population: 504582506, avg: 504582606, iqual: 1 },
    { year: '2021/10/05', population: 505674965, avg: 505685965 },
    { year: '2021/11/04', population: 507416607, avg: 507416700 },
    { year: '-', summary: false }
  ];

  //ngOnInit(): void {
  //  this.data = this.generateData();
  //}

  padding: any = { left: 10, top: 5, right: 10, bottom: 5 };

  titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };

  toolTipCustomFormatFn = (value: any, itemIndex: any, serie: any, group: any, categoryValue: any, categoryAxis: any): string => {
    let dataItem = this.data[itemIndex];
    return '<DIV style="text-align:left"><b>Información' +
      '</b><br /></b><br />' +
      //categoryValue + '</b><br />Min: ' +
      //dataItem.min + '°<br />Max: ' +
      //dataItem.max + '°<br />Average: ' +
      dataItem.avg + '°</DIV>';
  };

  bands() {
    let array = this.data;
    let obj: { Max: number; Min: number; }
    let test: any;
    let g: [];

    for (var i = 0; i < array.length; i++) {
      if (array[i].iqual == 1) {
        //g.push(array.length)
      }
    }

    console.log(test);
    console.log(g);

    //console.log(fechas);
    let bands = [];
    //bands.push({ minValue: fechas.FechaOne, maxValue: fechas.FechaTwo, fillColor: 'red', opacity: 0.2 });
    bands.push({ minValue: 0, maxValue: 3.5, fillColor: 'purple', opacity: 0.2 }, { minValue: 5, maxValue: 6, fillColor: 'purple', opacity: 0.2 });
    //console.log(arraytwo);
    //console.log(bands);
    return bands;
  }

  xAxis: any =
    {
      type: 'basic',
      dataField: 'year',
      displayText: 'Year',
      labels: {

        angle: -65
      },
      valuesOnTicks: true,
      tickMarks: {
        visible: true,
        interval: 1,
        color: '#020206'
      },
      //formatFunction: function (value) {
      //  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      //  return months[value.()];     
      //},
      bands: this.bands()
    };

  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return '90%';
    }

    return 850;
  }  

  valueAxis: any =
    {
      title: { text: 'Population<br>' },
      unitInterval: 1000000,
      //labels:
      //{
      //  formatFunction: (value: any): string => {
      //    return value / 1000000 + ' M';
      //  }
      //},
      gridLines: {
        visible: false
      }
    };

  seriesGroups: any[] =
    [
      {
        type: 'waterfall',
        series:
          [
            {
              dataField: 'population',
              //summary: 'summary',
              displayText: 'Population change',
              colorFunction: (value: any, itemIndex: any, serie: any, group: any): string => {
                if (itemIndex === (this.data.length - 1)) {
                  return '#3F3A3B'; // total
                }
                return (value < 0) ? '#D30E2F' /* red */ : '#24A037' /*green*/;
              }
            }
          ]
          //bands:
          //[
          //  { minValue: 1, maxValue: 1, color: '#00FF00', opacity: 0.15 }
          //]
      },
      {
      type: 'line',
      symbolType: 'square',
      toolTipFormatFunction: this.toolTipCustomFormatFn,
      series: [
        { dataField: 'avg', displayText: 'Average Temperature', opacity: 1, lineWidth: 2 }
      ]
      }
   ];

  generateData(): any[] {   
    for (var i = this.data.length - 2; i > 0; i--) {
      this.data[i].population -= this.data[i - 1].population;
    }
    console.log(this.data);
    return this.data;
  }
}

export interface ListaSemanaDetalle {
  Id: number;
  Descripcion: string;
  MostrarLinea: boolean;
  CambioImg: boolean;
}

export interface SkuList {
  Id: number;
  Sku: string;
  SkuId: number;
  SkuCodigo: number;
  MostrarOcultarLineasMes: boolean,
  MostrarOcultarLineasTabla: boolean;
  Meses: Array<DetalleMensual>;
}

export interface DetalleMensual {
  CantidadMeses: number;
  MesesArray: Array<MesArray>;
}

export interface MesArray{
  Mes: string;
  NMes: number;
  DetailSemana: boolean;
  CantidadSemanas: number;
  Lineas: Array<LineasMes>;
  Semanas: Array<Semanas>;
}

export interface Semanas {
  Semana: string;
  ParoProgramado: boolean;
  SemanaDetalle: Array<LineasMes>;
}

export interface LineasMes {
  LineaPrincipal: number,
  InventarioInicial: number,
  Cobertura: number;
  Ingresos: number;
  DespachosAsignados: number;
  DespachosSinAsignar: number;
  Gestiones: number;
  Consumos: number;
  InventarioFinal: number;
}


//import { Component, OnInit } from '@angular/core';

//@Component({
//  selector: 'app-root',
//  templateUrl: './app.component.html'
//})
//export class AppComponent implements OnInit {
//  data: any[];

//  ngOnInit(): void {
//    this.data = this.generateData();
//  }

//  padding: any = { left: 10, top: 5, right: 10, bottom: 5 };

//  titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };

//  xAxis: any = {
//    type: 'basic',
//    dataField: 'year',
//    displayText: 'Year',
//    labels: { angle: -85 },
//    valuesOnTicks: true
//  };

//  toolTipCustomFormatFn = (value: any, itemIndex: any, serie: any, group: any, categoryValue: any, categoryAxis: any): string => {
//    let dataItem = this.data[itemIndex];
//    return '<DIV style="text-align:left"><b>Month: ' +
//      categoryValue + '°<br />Average: ' +
//      dataItem.avg + '°</DIV>';
//  };

//  getWidth(): any {
//    if (document.body.offsetWidth < 850) {
//      return '90%';
//    }

//    return 850;
//  }

//  valueAxis: any = {
//    title: { text: 'Population<br>' },
//    unitInterval: 1,
//    labels: {
//      formatFunction: (value: any): string => {
//        return value / 1 + ' M';
//      }
//    },
//    gridLines: {
//      visible: false
//    }
//  };

//  seriesGroups: any[] = [
//    {
//      type: 'waterfall',
//      series: [
//        {
//          dataField: 'population',
//          summary: 'summary',
//          displayText: 'Population change',
//          colorFunction: (
//            value: any,
//            itemIndex: any,
//            serie: any,
//            group: any
//          ): string => {
//            if (itemIndex === this.data.length - 1) {
//              return '#3F3A3B'; // total
//            }
//            return value < 0 ? '#D30E2F' /* red */ : '#24A037' /*green*/;
//          }
//        }
//      ]
//    },
//    {
//      type: 'line',
//      symbolType: 'square',
//      toolTipFormatFunction: this.toolTipCustomFormatFn,
//      series: [
//        { dataField: 'avg', displayText: 'Average Temperature', opacity: 1, lineWidth: 2 }
//      ]
//    }
//  ];

//  generateData(): any[] {
//    let data: any[] = [
//      { year: 2003, population: 490815046, avg: 490815070 },
//      { year: 2004, population: 492709300, avg: 490759200 },
//      { year: 2005, population: 494774599, avg: 494774620 },
//      { year: 2006, population: 496633373, avg: 496633393 },
//      { year: 2007, population: 498408547, avg: 498408587 },
//      { year: 2008, population: 500418320, avg: 500418400 },
//      { year: 2009, population: 502186144, avg: 502186210 },
//      { year: 2010, population: 503379305, avg: 503379350 },
//      { year: 2011, population: 504961522, avg: 504961572 },
//      { year: 2012, population: 504582506, avg: 504582258 },
//      { year: 2013, population: 505674965, avg: 505675000 },
//      { year: 2014, population: 507416607, avg: 507416700 },
//      { year: 'Total', summary: true }
//    ];

//    for (var i = data.length - 2; i > 0; i--) {
//      data[i].population -= data[i - 1].population;
//    }

//    return data;
//  }
//}
