import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-visualizar-pedidos',
  templateUrl: './grid-visualizar-pedidos.component.html',
  styleUrls: ['./grid-visualizar-pedidos.component.css']
})
export class GridVisualizarPedidosComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridVisualizarPedidos', { static: false }) myGridVisualizarPedidos: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'number' },
        { name: 'Fecha', type: 'string' },
        { name: 'Descripcion', type: 'string' },
        { name: 'Ruta', type: 'number' },
        { name: 'Cod_Producto', type: 'number' },
        { name: 'CantidadEnPiezas', type: 'number' },
        { name: 'Ajuste_1_Dia', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: '#', datafield: 'Id', cellsalign: 'center', align: 'center', filterable: false, width: '12.5%' },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', filterable: false, cellsformat: 'YYYY-MM-DD', width: '15%' },
      { text: 'Descripción', datafield: 'Descripcion', cellsalign: 'left', align: 'center', width: '20%' },
      { text: 'Ruta', datafield: 'Ruta', cellsalign: 'center', align: 'center', width: '17.5%' },
      { text: 'Cod_Producto', datafield: 'Cod_Producto', cellsalign: 'center', align: 'center', width: '12.5%' },
      { text: 'CantidadEnPiezas', datafield: 'CantidadEnPiezas', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Ajuste_1_Dia', datafield: 'Ajuste_1_Dia', cellsalign: 'center', align: 'center', filterable: false, width: '12.5%' }
    ];

}
