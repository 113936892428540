import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VestaMenuComponent } from 'vesta-menu'
import { AccesoDenegadoComponent } from 'vesta';
import { AuthorizationGuard } from 'vesta-authentication';
import { AlertasComponent } from './screen/alertas/alertas/alertas.component';
import { ModificacionesForecastComponent } from './screen/modificaciones-forecast/modificaciones-forecast/modificaciones-forecast.component';


//import { GraficaTestComponent } from './screen/grafica-test/grafica-test/grafica-test.component';
import { GraficoComponent } from './screen/grafico/grafico/grafico.component';
import { GraficaTestModule } from './screen/grafica-test/grafica-test.module';
import { GraficaTestComponent } from './screen/grafica-test/grafica-test/grafica-test.component';
import { UpiComponent } from './screen/upi/upi/upi.component';
import { PedidosComponent } from './screen/pedidos/pedidos/pedidos.component';

const routes: Routes = [
  { path: "", redirectTo: "menu", pathMatch: "full" },
  {
    path: "login/accessdenied", component: AccesoDenegadoComponent
  },
  {
    path: "menu",
    component: VestaMenuComponent, canActivate: [AuthorizationGuard]
  },
  {
    path: 'operaciones', 
    children: [
      {
        path: 'gestiónderequerimientos',
        loadChildren: "../app/screen/despachos-ordenes/despachos-ordenes.module#DespachosOrdenesModule",
        canActivate: [AuthorizationGuard]
      }
    ]
  },
  {
    path: 'reportes',
    children: [      
      {
        path: 'upi',
        loadChildren: "../app/screen/upi/upi.module#UpiModule",
        canActivate: [AuthorizationGuard],
      }
    ]
  },
  { path: 'upi', component: UpiComponent },
  { path: 'pedidos', component: PedidosComponent, canActivate: [AuthorizationGuard] },
  { path: 'alertas', component: AlertasComponent },
  { path: 'modificacionesforecast', component: ModificacionesForecastComponent, canActivate: [AuthorizationGuard] },
  { path: 'test', component: GraficoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
