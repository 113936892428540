import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-gestiones-semanas',
  templateUrl: './grid-gestiones-semanas.component.html',
  styleUrls: ['./grid-gestiones-semanas.component.css']
})
export class GridGestionesSemanasComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridGestionesSemanas', { static: false }) myGridGestionesSemanas: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'CodigoGestion', type: 'string' },
        { name: 'Semana', type: 'number' },
        { name: 'SitioActual', map: 'SitioActual>Nombre', type: 'string' },
        { name: 'Unidad', type: 'number' },
      ],
      id: "CodigoGestion",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      { text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', width: '33%' },
      { text: 'Gestión', datafield: 'CodigoGestion', cellsalign: 'center', align: 'center', filterable: false, width: '17%' },
      { text: 'Semana', datafield: 'Semana', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Sitio', datafield: 'SitioActual', cellsalign: 'left', align: 'center', filterable: false, width: '25%' },
      {
        text: 'Unidades', datafield: 'Unidad', cellsalign: 'center', align: 'center', filterable: false, width: '10%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates, columns, element) => {
          let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum + '</div>';
          return renderstring;
        }
      }
    ];
}
