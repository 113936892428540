import { Component, OnInit,ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-gestiones-finalizadas-mes',
  templateUrl: './grid-gestiones-finalizadas-mes.component.html',
  styleUrls: ['./grid-gestiones-finalizadas-mes.component.css']
})

export class GridGestionesFinalizadasMesComponent implements OnInit {
  constructor() { }
  @ViewChild('myGridGestionesFinalizadasMes', { static: false }) myGridGestionesFinalizadasMes: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'CodigoGestion', type: 'string' },
        { name: 'SemanaEtaModificada', type: 'number' },
        { name: 'EtaModificada', type: 'date' },
        { name: 'SitioActual', map: 'SitioActual>Nombre', type: 'string' },
        { name: 'FechaMigo', type: 'date' },
        { name: 'Migo', type: 'string' },              
        { name: 'Unidad', type: 'number' },
      ],
      id: "CodigoGestion",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      //{ text: '#', datafield: 'Id', cellsalign: 'center', align: 'center', filterable: false, width: '5%' },
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      //{ text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', width: '25%' },
      { text: 'Gestión', datafield: 'CodigoGestion', cellsalign: 'center', align: 'center', filterable: false, width: '13%' },
      { text: 'Semana ETA Proyectada', datafield: 'SemanaEtaModificada', cellsalign: 'center', align: 'center', filterable: false, width: '13%' },
      { text: 'ETA Proyectada', datafield: 'EtaModificada', cellsalign: 'center', align: 'center', width: '12%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      { text: 'Sitio', datafield: 'SitioActual', cellsalign: 'left', align: 'center', filterable: false, width: '25%' },
      { text: 'Fecha Migo', datafield: 'FechaMigo', cellsalign: 'center', align: 'center', width: '12%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      { text: 'Migo', datafield: 'Migo', cellsalign: 'left', align: 'center', filterable: false, width: '12%' },
      {
        text: 'Unidades', datafield: 'Unidad', cellsalign: 'center', align: 'center', filterable: false, width: '8%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates, columns, element) => {
          let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum + '</div>';
          return renderstring;
        }
      }
    ];
}
