import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { JqxGridModule } from '../../ng-modules/jqx-grid/jqx-grid.module';
import { PedidosComponent } from './pedidos/pedidos.component';
import { GridVisualizarPedidosComponent } from './grid-visualizar-pedidos/grid-visualizar-pedidos.component';

@NgModule({
  declarations: [PedidosComponent, GridVisualizarPedidosComponent],
  imports: [
    CommonModule,
    FormsModule,
    JqxGridModule
  ]
})
export class PedidosModule { }
