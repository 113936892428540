import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { jqxChartComponent } from '../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';

@NgModule({
  declarations: [
    jqxChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    jqxChartComponent
  ]
})

export class JqxChartModule { }
