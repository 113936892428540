import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraficoComponent } from './grafico/grafico.component';
import { JqxChartModule } from '../../ng-modules/jqx-chart/jqx-chart.module';
//import { NgApexchartsModule } from "ng-apexcharts";


@NgModule({
  declarations: [GraficoComponent],
  imports: [
    CommonModule,
    JqxChartModule
    //NgApexchartsModule
  ]
})
export class GraficoModule { }
