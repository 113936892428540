import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { DetalleRequerimientoParaDespacho } from '../../../objects/upi-object';

@Component({
  selector: 'app-grid-despachos',
  templateUrl: './grid-despachos.component.html',
  styleUrls: ['./grid-despachos.component.css']
})
export class GridDespachosComponent implements OnInit, AfterViewInit {

  constructor() { }
  @ViewChild('myGridDespachos', { static: false }) myGridDespachos: jqxGridComponent;
  @Input() DivisionDetalleRequerimientos: Array<DetalleRequerimientoParaDespacho>;
  @Output() valueChange = new EventEmitter();

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.source.localdata = this.DivisionDetalleRequerimientos;
    this.dataAdapter.dataBind();
  }

  CellClick(event: any) {
    let rowData = event.args.row.bounddata;
    let dataField = event.args.datafield;

    if (dataField == GridDividirRequerimientosColumns.EliminarClick) this.EliminarClick(rowData);
    //else if (dataField == GridRequisasCreadasColumns.AgregarSkuRequisasClick) this.AgregarSkuRequisasClick(rowData);
  }

  //Boton para eliminar division
  EliminarCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="25" width="25" src="../../../../assets/icons/stop.png"></div>'
  }

  //UniqueDelete: number;
  EliminarClick(rowData: DetalleRequerimientoParaDespacho) {
    this.valueChange.emit(rowData);
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'number' },
        { name: 'SkuId', type: 'string' },
        { name: 'SkuDescripcion', type: 'string' },
        { name: 'Cantidad', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: '#', datafield: 'Id', cellsalign: 'center', align: 'center', filterable: false, width: '5%' },
      { text: 'Sku', datafield: 'SkuDescripcion', cellsalign: 'left', align: 'center', width: '58%' },
      { text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '25%' },
      { text: "Eliminar", datafield: "EliminarClick", cellsalign: 'center', align: "center", filterable: false, width: '12%', cellsrenderer: this.EliminarCellRenderer }
    ];

}

enum GridDividirRequerimientosColumns {
  EliminarClick = 'EliminarClick'
}
