import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
//import { GridGestionesComponent } from '../grid-gestiones/grid-gestiones.component';
import { Alertas } from '../../../objects/alertas-object';
//import { jqxDateTimeInputComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput';
import { ModalComponent } from 'modal';
import { DialogService } from 'vesta';
import * as Enumerable from 'linq';
import * as moment from "moment";
require("../../../extensions/moment-extends");

@Component({
  selector: 'app-grid-alertas',
  templateUrl: './grid-alertas.component.html',
  styleUrls: ['./grid-alertas.component.css']
})
export class GridAlertasComponent implements OnInit {

  constructor(private DialoService: DialogService) { }

  @Output("ViewAlertaClick") ViewAlertaClick = new EventEmitter();
  @Output("SnoozeRefresh") SnoozeRefresh = new EventEmitter();
  @Input('data') data: Array<Gestiones> = [];
  @ViewChild('myGridAlertas', { static: false }) myGridAlertas: jqxGridComponent;
  //@ViewChild(GridGestionesComponent, { static: false }) myGridGestiones: GridGestionesComponent;
  @ViewChild("ModalSnooze", { static: false }) ModalSnooze: ModalComponent;
  @ViewChild("ModalView", { static: false }) ModalView: ModalComponent;
  //@ViewChild("DesdeFecha", { static: false }) DesdeFecha: jqxDateTimeInputComponent;
  //@ViewChild("DesdeHora", { static: false }) DesdeHora: jqxDateTimeInputComponent;
  //@ViewChild("HastaFecha", { static: false }) HastaFecha: jqxDateTimeInputComponent;
  //@ViewChild("HastaHora", { static: false }) HastaHora: jqxDateTimeInputComponent;

  ngOnInit() {
    this.source.localdata = this.data;
    this.dataAdapter.dataBind();
  }

  ModificacionGestion: Gestiones[] = [
    {
      "Id": 1,
      "ReferenciaGestion": "CD-CD-21-63",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 500.00
    },
    {
      "Id": 2,
      "ReferenciaGestion": "CD-CD-21-04",
      "FechaETD": "03-01-2021",
      "Unidad": "KG",
      "Cantidad": 1500.00
    },
    {
      "Id": 3,
      "ReferenciaGestion": "CD-CD-21-05",
      "FechaETD": "24-01-2021",
      "Unidad": "LB",
      "Cantidad": 100.00
    },
    {
      "Id": 4,
      "ReferenciaGestion": "CD-CD-20-100",
      "FechaETD": "05-02-2021",
      "Unidad": "KG",
      "Cantidad": 115.00
    },
    {
      "Id": 5,
      "ReferenciaGestion": "CD-CD-21-11",
      "FechaETD": "08-05-2021",
      "Unidad": "KG",
      "Cantidad": 125.00
    },
    {
      "Id": 6,
      "ReferenciaGestion": "CD-CD-21-52",
      "FechaETD": "10-03-2021",
      "Unidad": "KG",
      "Cantidad": 425.00
    },
    {
      "Id": 7,
      "ReferenciaGestion": "CD-CD-20-101",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 400.00
    },
    {
      "Id": 8,
      "ReferenciaGestion": "CD-CD-22-65",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 50.00
    },
    {
      "Id": 9,
      "ReferenciaGestion": "CD-CD-21-57",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 315.00
    },
    {
      "Id": 10,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 11,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 12,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 13,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 14,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 15,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 16,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 17,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 18,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 19,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 20,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 21,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    }
  ]

  Vistas: Vistas[] = [
    {
      Id: 1,
      Descripcion: "Proxima Semana"
    },
    {
      Id: 1,
      Descripcion: "Proximo Mes"
    },
    {
      Id: 1,
      Descripcion: "Proxim 2 Meses"
    },
    {
      Id: 1,
      Descripcion: "Proximo 3 Meses"
    },
    {
      Id: 1,
      Descripcion: "Proximo 6 Meses"
    }
  ]
  Vista: Vistas;
  EnabledVista: boolean = false;

  check: boolean = false;
  DesdeFecha: string;
  DesdeHora: string;

  HastaFecha: string;
  HastaHora: string;

  CellClick(event: any) {
    let rowData = event.args.row.bounddata;
    let dataField = event.args.datafield;

    if (dataField == GridAlertasColumns.SnoozeClick) this.SnoozeAlertasClick(rowData);
    else if (dataField == GridAlertasColumns.ViewClick) this.ViewClick(rowData);
  }

  //Boton snooze
  SnoozeAlertasCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    if (rowdata.Snooze == false) {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.2rem;"><img style="cursor: pointer" height="30" width="30" src="../../../../assets/icons/snooze.png"></div>'
    }
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.2rem; opacity:0.5;"><img style="cursor: pointer" height="30" width="30" src="../../../../assets/icons/snooze.png"></div>'
    //return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="30" width="30" src="../../../../assets/icons/snooze.png"></div>'
  }

  SnoozeAlertasClick(rowData) {
    if (rowData.Snooze == false) {
      this.check = false;
      this.DesdeFecha = null;
      this.DesdeHora = null;
      this.HastaFecha = null;
      this.HastaHora = null;
      this.ModalSnooze.showModal();
    } else {
      this.DialoService.NotificacionWarning("Snooze ya ha sido aplicado");
    }    
  }

  Alertas() {
    this.check = false;
    let FechaDesde = this.DesdeFecha;
    let HoraDesde: Array<string> = this.DesdeHora.split(':');
    let objHoraDesde: { Hora: string, Minutos: string }
    let HoraDesdeFinal: string;
    let FechaHoraDesde: string;

    let FechaHasta = this.HastaFecha;
    let HoraHasta: Array<string> = this.HastaHora.split(':');
    let objHoraHasta: { Hora: string, Minutos: string }
    let HoraHastaFinal: string;
    let FechaHoraHasta: string;

    for (var i = 0; i < HoraDesde.length; i++) {
      objHoraDesde = { Hora: HoraDesde[0], Minutos: HoraDesde[1] };      
    }
    HoraDesdeFinal = objHoraDesde.Hora + ':' + objHoraDesde.Minutos + ':' + '00';
        
    for (var i = 0; i < HoraHasta.length; i++) {
      objHoraHasta = { Hora: HoraHasta[0], Minutos: HoraHasta[1] };
    }
    HoraHastaFinal = objHoraHasta.Hora + ':' + objHoraHasta.Minutos + ':' + '00';

    FechaHoraDesde = FechaDesde + ' ' + HoraDesdeFinal;
    FechaHoraHasta = FechaHasta + ' ' + HoraHastaFinal;

    this.DialoService.NotificacionSucess("Snooze ha sido creado con éxito");
    this.ModalSnooze.closeModal();

    this.SnoozeRefresh.emit();
  }

  //Boton view
  ViewCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="30" width="30" src="../../../../assets/icons/lupa.png"></div>'
  }

  ViewClick(rowData: Alertas) {
    this.ViewAlertaClick.emit(rowData);
    //this.Vista = null;
    //this.EnabledVista = false;
    //this.Vistas;
    //this.ModalView.showModal();
  }

  
  //VistaCombo() {
  //  if (this.Vista == null) {
  //    this.EnabledVista = false;
  //  } else {
  //    this.EnabledVista = true;
  //    this.ModificacionGestion;
  //  }
  //}

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'string' },
        { name: 'Timer', type: 'string' },
        { name: 'Categoria', type: 'string' },
        { name: 'Clasificacion', type: 'string' },
        { name: 'ME', type: 'number' },
        { name: 'Descripcion', type: 'string' },
        { name: 'Planta', type: 'string',  },
        { name: 'CoberturaMeta', type: 'string' },
        { name: 'Cobertura', type: 'string' },
        { name: 'Snooze', type: 'boolean' }        
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      //{ text: 'Id', datafield: 'Id', hidden: true },
      { text: "", datafield: "ViewClick", cellsalign: 'center', align: "center", filterable: false, width: '4%', cellsrenderer: this.ViewCellRenderer },
      { text: 'Timer', datafield: 'Timer', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Categoria', datafield: 'Categoria', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Clasificación', datafield: 'Clasificacion', cellsalign: 'center', align: 'center', width: '12%' },
      { text: 'ME', datafield: 'ME', cellsalign: 'center', align: 'center', filterable: false, width: '8%' },
      { text: 'Descripción', datafield: 'Descripcion', cellsalign: 'left', align: 'center', width: '20%' },
      { text: 'Planta', datafield: 'Planta', cellsalign: 'center', align: 'center', width: '10%' },
      { text: 'Cobertura Meta', datafield: 'CoberturaMeta', cellsalign: 'center', align: 'center', filterable: false, width: '9%' },
      { text: 'Cobertura', datafield: 'Cobertura', cellsalign: 'center', align: 'center', filterable: false, width: '9%' },
      { text: "Snooze", datafield: "SnoozeClick", cellsalign: 'center', align: "center", filterable: false, width: '8%', cellsrenderer: this.SnoozeAlertasCellRenderer }
    ];
}

enum GridAlertasColumns {
  SnoozeClick = 'SnoozeClick',
  ViewClick = 'ViewClick'
}

export interface Vistas {
  Id: number;
  Descripcion: string;
}

export interface Gestiones {
  Id: number;
  ReferenciaGestion: string;
  FechaETD: string;
  Unidad: string;
  Cantidad: number;
}
