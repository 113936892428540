import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpiModule } from './screen/upi/upi.module';
import { AlertasModule } from './screen/alertas/alertas.module';
import { PedidosModule } from './screen/pedidos/pedidos.module';
import { ModificacionesForecastModule } from './screen/modificaciones-forecast/modificaciones-forecast.module';
import { GraficaTestModule } from './screen/grafica-test/grafica-test.module';
import { GraficoModule } from './screen/grafico/grafico.module';
import { VestaModule } from 'vesta';
import { VestaMenuModule } from 'vesta-menu';
import { VestaAuthenticationModule } from 'vesta-authentication';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UpiModule,
    AlertasModule,
    PedidosModule,
    ModificacionesForecastModule,
    GraficaTestModule,
    GraficoModule,
    VestaAuthenticationModule.forRoot(environment.authentication),
    VestaMenuModule,
    VestaModule.forRoot({ isAuthentication: true })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
