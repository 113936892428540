import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { RequerimientosAfectados } from '../../../objects/modificaciones-forecast-object';

@Component({
  selector: 'app-grid-requerimientos-afectados',
  templateUrl: './grid-requerimientos-afectados.component.html',
  styleUrls: ['./grid-requerimientos-afectados.component.css']
})
export class GridRequerimientosAfectadosComponent implements OnInit {

  constructor() { }

  @ViewChild('myGridRequerimientosAfectados', { static: false }) myGridRequerimientosAfectados: jqxGridComponent;
  @Input('data') data: Array<RequerimientosAfectados> = [];

  ngOnInit() {
    this.source.localdata = this.data;
    this.dataAdapter.dataBind();
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'string' },
        { name: 'ReferenciaRequerimiento', type: 'string' },
        { name: 'Fecha', type: 'string' },
        { name: 'CantidadNoAfectada', type: 'number' },
        { name: 'CantidadAfectada', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Referencia Requerimiento', datafield: 'ReferenciaRequerimiento', cellsalign: 'center', align: 'center', width: '30%' },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', filterable: false, width: '20%' },
      { text: 'Cantidad No Afectada', datafield: 'CantidadNoAfectada', cellsalign: 'center', align: 'center', filterable: false, width: '25%' },
      { text: 'Cantidad Afectada', datafield: 'CantidadAfectada', cellsalign: 'center', align: 'center', filterable: false, width: '25%' }
      //{ text: 'Fecha Inicio', datafield: 'FechaInicio', cellsalign: 'center', align: 'center', cellsformat: 'dd/MM/yyyy hh:mm:ss', filterable: false, width: '15%' }
    ];

}
