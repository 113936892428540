import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-despachos-asignados-mensual-sku',
  templateUrl: './grid-despachos-asignados-mensual-sku.component.html',
  styleUrls: ['./grid-despachos-asignados-mensual-sku.component.css']
})
export class GridDespachosAsignadosMensualSkuComponent implements OnInit, AfterViewInit {

  constructor() { }
  @ViewChild('myGridDespachosAsignadosMensualSku', { static: false }) myGridDespachosAsignadosMensualSku: jqxGridComponent;
  //@Input() despachosasignados: Array<DespachosModalesII>;

  ngOnInit() {    
  }

  ngAfterViewInit() {
    //this.source.localdata = this.despachosasignados;
    //this.dataAdapter.dataBind();
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Mes', type: 'date' },
        { name: 'Fecha', type: 'date' },
        { name: 'GetSemana', type: 'string' },
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'CodigoDespacho', type: 'string' },
        { name: 'Cantidad', type: 'number' }
      ],
      id: "CodigoDespacho",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      { text: 'Mes', datafield: 'Mes', cellsalign: 'left', align: 'center', width: '20%' },
      {
        text: 'Semana', datafield: 'GetSemana', cellsalign: 'center', align: 'center', filterable: false, width: '10%'
      },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', width: '20%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      { text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', width: '35%' },
      { text: 'Despacho', datafield: 'CodigoDespacho', cellsalign: 'center', align: 'center', filterable: false, width: '15%' },
      {
        text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '15%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates) => {
          if (aggregates.sum == undefined) {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + 0 + '</div>';
            return renderstring;
          } else {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum + '</div>';
            return renderstring;
          }
        }
      }
    ];

}

export interface DespachosModalesII {
  Id: string;
  Mes: string;
  Nmes: number;
  Fecha: Date
  ProveedorNombre: string;
  CodigoDespacho: string;
  Cantidad?: number;
  GetSemana: string;
}
