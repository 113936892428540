import { Component, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { GridAlertasComponent } from '../grid-alertas/grid-alertas.component';
import { GridGestionesComponent } from '../grid-gestiones/grid-gestiones.component';
import { Alertas, Gestiones, Vistas, Eventos } from '../../../objects/alertas-object';
import { ModalComponent } from 'modal';
import { DialogService } from 'vesta';
//import { FileshareService, FileshareComponent } from 'fileshare';
//import { USER_AUTH, IUserAuth } from 'vesta-authentication';
import * as Enumerable from 'linq';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css']
})
export class AlertasComponent implements OnInit, AfterViewInit {

  constructor(private DialogService: DialogService,
    //private fileShareService: FileshareService, @Inject(USER_AUTH) private user: IUserAuth
  ) { }

  @ViewChild(GridAlertasComponent, { static: false }) myGridAlertas: GridAlertasComponent;
  @ViewChild(GridGestionesComponent, { static: false }) myGridGestiones: GridGestionesComponent;
  @ViewChild("ModalEvento", { static: false }) ModalEvento: ModalComponent;
  //@ViewChild(FileshareComponent, { static: false }) FileShare: FileshareComponent;

  Alertas: Alertas[] = [
    {
      "Id": 1,
      "Timer": "05:25:00",
      "Categoria": "Foods",
      "Clasificacion": "Schilos",
      "ME": 2006351, 
      "Descripcion": "Tapa Chile 5 Onzas", 
      "Planta": "Dixie", 
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 2,
      "Timer": "05:40:00",
      "Categoria": "Foods",
      "Clasificacion": "Salsas",
      "ME": 2006352,
      "Descripcion": "Pasta de tomate extra espesa Issima 106g",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 3,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Xpac",
      "ME": 2006353,
      "Descripcion": "Mazola Palma, Soya y Maiz 750 ml",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 4,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Xpac",
      "ME": 2006353,
      "Descripcion": "Mazola Palma, Soya y Maiz 750 ml",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 5,
      "Timer": "05:40:00",
      "Categoria": "Home Care",
      "Clasificacion": "Poli 375G",
      "ME": 2006353,
      "Descripcion": "Zixx Ultra blanco 3 pack bola 375g",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 6,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Manteca",
      "ME": 2006353,
      "Descripcion": "Mazola manteca plus 1 libra",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 7,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 8,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 9,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 10,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 11,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 12,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Xpac",
      "ME": 2006353,
      "Descripcion": "Mazola Palma, Soya y Maiz 750 ml",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    }
  ];
  AlertasTwo: Alertas[] = [
    {
      "Id": 1,
      "Timer": "05:25:00",
      "Categoria": "Foods",
      "Clasificacion": "Schilos",
      "ME": 2006351,
      "Descripcion": "Tapa Chile 5 Onzas",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 2,
      "Timer": "05:40:00",
      "Categoria": "Foods",
      "Clasificacion": "Salsas",
      "ME": 2006352,
      "Descripcion": "Pasta de tomate extra espesa Issima 106g",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 3,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Xpac",
      "ME": 2006353,
      "Descripcion": "Mazola Palma, Soya y Maiz 750 ml",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 4,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Xpac",
      "ME": 2006353,
      "Descripcion": "Mazola Palma, Soya y Maiz 750 ml",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 5,
      "Timer": "05:40:00",
      "Categoria": "Home Care",
      "Clasificacion": "Poli 375G",
      "ME": 2006353,
      "Descripcion": "Zixx Ultra blanco 3 pack bola 375g",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": true
    },
    {
      "Id": 6,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Manteca",
      "ME": 2006353,
      "Descripcion": "Mazola manteca plus 1 libra",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 7,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 8,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 9,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 10,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 11,
      "Timer": "05:40:00",
      "Categoria": "SAVOURY",
      "Clasificacion": "Sopas",
      "ME": 2006353,
      "Descripcion": "Tapa Issima camaron con Chile",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    },
    {
      "Id": 12,
      "Timer": "05:40:00",
      "Categoria": "Fats & Oil",
      "Clasificacion": "Xpac",
      "ME": 2006353,
      "Descripcion": "Mazola Palma, Soya y Maiz 750 ml",
      "Planta": "Dixie",
      "CoberturaMeta": 1.50,
      "Cobertura": 1.40,
      "Snooze": false
    }
  ];
  Eventos: Eventos[] = [
    {
      Id: 1,
      Descripcion: "CAIDA SISTEMA SENASA"
    },
    {
      Id: 2,
      Descripcion: "HUELGAS"
    },
    {
      Id: 3,
      Descripcion: "MAL TIEMPO EN SITIO ORIGEN"
    }
  ]
  ModificacionGestion: Gestiones[] = [
    {
      "Id": 1,
      "ReferenciaGestion": "CD-CD-21-63",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 500.00
    },
    {
      "Id": 2,
      "ReferenciaGestion": "CD-CD-21-04",
      "FechaETD": "03-01-2021",
      "Unidad": "KG",
      "Cantidad": 1500.00
    },
    {
      "Id": 3,
      "ReferenciaGestion": "CD-CD-21-05",
      "FechaETD": "24-01-2021",
      "Unidad": "LB",
      "Cantidad": 100.00
    },
    {
      "Id": 4,
      "ReferenciaGestion": "CD-CD-20-100",
      "FechaETD": "05-02-2021",
      "Unidad": "KG",
      "Cantidad": 115.00
    },
    {
      "Id": 5,
      "ReferenciaGestion": "CD-CD-21-11",
      "FechaETD": "08-05-2021",
      "Unidad": "KG",
      "Cantidad": 125.00
    },
    {
      "Id": 6,
      "ReferenciaGestion": "CD-CD-21-52",
      "FechaETD": "10-03-2021",
      "Unidad": "KG",
      "Cantidad": 425.00
    },
    {
      "Id": 7,
      "ReferenciaGestion": "CD-CD-20-101",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 400.00
    },
    {
      "Id": 8,
      "ReferenciaGestion": "CD-CD-22-65",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 50.00
    },
    {
      "Id": 9,
      "ReferenciaGestion": "CD-CD-21-57",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 315.00
    },
    {
      "Id": 10,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 11,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 12,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 13,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 14,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 15,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 16,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 17,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 18,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 19,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 20,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 21,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    }
  ]
  Vistas: Vistas[] = [
    {
      Id: 1,
      Descripcion: "Proxima Semana"
    },
    {
      Id: 1,
      Descripcion: "Proximo Mes"
    },
    {
      Id: 1,
      Descripcion: "Proxim 2 Meses"
    },
    {
      Id: 1,
      Descripcion: "Proximo 3 Meses"
    },
    {
      Id: 1,
      Descripcion: "Proximo 6 Meses"
    }
  ]
  Vista: Vistas;
  

  DetailReferencia: boolean = false;
  DetailVista: boolean = false;
  Row: Alertas;

  ngOnInit() {
    this.Eventos;
  }

  ngAfterViewInit() {
    this.myGridAlertas.source.localdata = this.Alertas;
    this.myGridAlertas.dataAdapter.dataBind();    
  }

  Regresar() {
    this.DetailReferencia = false;
    this.DetailVista = false;
    this.Alertas = Enumerable.from(this.Alertas).orderBy(o => o.Id).toArray();
  }

  ViewDetails(event: Alertas) {
    this.DetailReferencia = true;
    this.Vista = null;
    this.Evento = null;
    this.Vistas;
    this.Row = event;    
  }

  SnoozeReload(event) {
    this.myGridAlertas.source.localdata = Enumerable.from(this.AlertasTwo).orderBy(o => o.Id).toArray();
    this.myGridAlertas.dataAdapter.dataBind();
  }

  SelectGrid(): Array<Gestiones> {
    let indixes = this.myGridGestiones.myGridGestiones.getselectedrowindexes() as number[];
    let localData = Enumerable.from(this.myGridGestiones.source.localdata as Array<Gestiones>);
    let array: Array<Gestiones> = [];
    indixes.forEach(f => {
      array.push(localData.elementAt(f));
    })

    return array;
  }

  VistaCombo() {
    if (this.Vista == null) {
      this.DetailVista = false;
    } else {
      this.DetailVista = true;      
      this.ModificacionGestion;
    }
  }

  GestionesMarcadas: Array<string>;
  AgregarEvento() {
    this.FechaEvento = null;
    this.HoraEvento = null;
    this.Evento = null;
    let arrayRows = this.SelectGrid();
    if (arrayRows.length == 0) {
      this.DialogService.NotificacionWarning("Seleccione una o mas gestiones para crear evento");
    } else {
      this.GestionesMarcadas = Enumerable.from(arrayRows).select(s => s.ReferenciaGestion).toArray();
      this.ModalEvento.showModal();
    }    
  }

  Evento: Eventos;
  FechaEvento: string;
  HoraEvento: string;
  ObservacionEvento: string;
  CrearEvento() {
    if (this.Evento == null) {
      this.DialogService.NotificacionWarning("Seleccione un evento");
    } else if (this.FechaEvento == null) {
      this.DialogService.NotificacionWarning("Seleccione una fecha de evento");
    } else if (this.HoraEvento == null) {
      this.DialogService.NotificacionWarning("Seleccione una hora de evento");
    } else {
              let Fecha = this.FechaEvento;
              let Hora: Array<string> = this.HoraEvento.split(':');
              let objHora: { Hora: string, Minutos: string }
              let HoraFinal: string;
              let FechaHora: string;

              for (var i = 0; i < Hora.length; i++) {
                objHora = { Hora: Hora[0], Minutos: Hora[1] };
              }

              HoraFinal = objHora.Hora + ':' + objHora.Minutos + ':' + '00';
              FechaHora = Fecha + ' ' + HoraFinal;

              this.DialogService.NotificacionSucess("Evento creado exito");
              this.DetailReferencia = false;
              this.ModalEvento.closeModal();

              this.DialogService.ShowConfirm("¿Desea adjuntar documento al evento?", "")
                .subscribe(responses => {
                  if (responses) {
                    this.DialogService.NotificacionSucess("Documento digitalizado con éxito");                    
                    //this.fileShareService.UploadDocumento("0", this.user.Id, 1, "Evento", "1", this.codigoSistema, "0")
                    //  .(response => {
                    //    //this.fileShareService.GetRespuestaUploadDocumento(response)
                    //    //  .subscribe(responses => {
                    //    //    if (Enumerable.from(responses).count() == 0) return void 0;
                    //    //    if (responses[0].Estado == 1) {}
                    //    //  })
                    //  });
                  } else {
                    this.DialogService.NotificacionWarning("Se eligio no adjuntar documento");
                    this.DetailReferencia = false;
                  }
                });
           }
  }
}
