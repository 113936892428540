import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-asignar-cantidad-a-sku',
  templateUrl: './grid-asignar-cantidad-a-sku.component.html',
  styleUrls: ['./grid-asignar-cantidad-a-sku.component.css']
})
export class GridAsignarCantidadASkuComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridAsignarCantidadaSku', { static: false }) myGridAsignarCantidadaSku: jqxGridComponent;
  
  ngOnInit() {
  }

  Cantidad: number;
  Rowclick(event: any): void {
    this.Cantidad = event.args.row.bounddata.Cantidad;
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'number' },
        { name: 'SkuId', type: 'number' },
        { name: 'Sku', type: 'string' },
        { name: 'Cantidad', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Sku', datafield: 'Sku', cellsalign: 'center', align: 'center', editable: false, width: '70%' },
      {
        text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '30%',
        validation: (cell: any, value: any): any => {
          let valor = String(value);
          let valid: boolean;
          if (valor.match("^[0-9]*$")) {
            valid = false;
          } else {
            valid = true
          }

          if (value == "") value = null;

          if (value < 0) {
            return { message: 'Cantidad no puede ser menor o igual a cero', result: false };        
          } else if (value == 0) {
            return { message: 'Cantidad no puede ser cero', result: false };
          } else if (valid == true) {
            return { message: 'Solo puede ingresar números', result: false };
          } else if (value == null) {
            return { result: true };
          }
          return true;
        }
      }
    ];
}
