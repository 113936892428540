import * as moment from "moment";

moment.prototype.isMayor = function (input) {
    var fecha = this;
    if (input === null) {
        var value = fecha.diff(input);
        if (value > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
};

moment.prototype.FormatoFecha = function () {
    var fecha = this;
    return fecha.format("DD/MM/YYYY HH:mm:ss");
};

moment.prototype.FormatoFechaCSharp = function () {
    var fecha = this;
    return fecha.format("YYYY-MM-DD HH:mm:ss");
};

moment.prototype.FormatoFechaShort = function () {
    var fecha = this;
    return fecha.format("YYYY-MM-DD");
};
