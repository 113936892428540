import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { DialogService } from 'vesta';

@Component({
  selector: 'app-grid-dividir-sku',
  templateUrl: './grid-dividir-sku.component.html',
  styleUrls: ['./grid-dividir-sku.component.css']
})
export class GridDividirSkuComponent implements OnInit {

  constructor(private DialogService: DialogService) { }
  @ViewChild('myGridDividirSku', { static: false }) myGridDividirSku: jqxGridComponent;
  @Output() AddRegistro = new EventEmitter();

  ngOnInit() {
  }

  CellClick(event: any) {
    let rowData = event.args.row.bounddata;
    let dataField = event.args.datafield;

    if (dataField == GridDividirSkuColumns.AgregarClick) this.AgregarClick(rowData);
    //else if (dataField == GridRequisasCreadasColumns.AgregarSkuRequisasClick) this.AgregarSkuRequisasClick(rowData);
  }

  Cantidad: number;
  CantidadIngresada: number;
  Rowclick(event: any): void {
    this.Cantidad = event.args.row.bounddata.Cantidad;
    this.CantidadIngresada = event.args.row.bounddata.CantidadIngresada;;
  }

  //Boton para agregar division sku
  AgregarCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="25" width="25" src="../../../../assets/icons/plus.png"></div>'
  }

  AgregarClick(rowData) {

    if (rowData.CantidadIngresada == 0) {
      this.DialogService.NotificacionWarning("Cantidad ingresada es cero no puede agregar mas Sku");
    } else if (rowData.CantidadIngresada > rowData.Cantidad) {
      this.DialogService.NotificacionWarning("Cantidad es mayor a la cantidad ingresada");
    } else if (rowData.CantidadIngresada == 0) {
      this.DialogService.NotificacionWarning("Cantidad no puede ser cero");
    } else if (rowData.CantidadIngresada == null) {
      this.DialogService.NotificacionWarning("Cantidad no puede ir vacía");
    } else if (rowData.CantidadIngresada < 0) {
      this.DialogService.NotificacionWarning("Cantidad no puede ser negativa");
    } else {
              this.AddRegistro.emit(rowData);
              rowData.CantidadIngresada == null
              this.myGridDividirSku.refresh();
           }
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Id', type: 'number' },
        { name: 'SkuId', type: 'number' },
        { name: 'Sku', type: 'string' },
        { name: 'Cantidad', type: 'number' },
        { name: 'CantidadIngresada', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: '#', sortable: false, filterable: false, editable: false,
        groupable: false, draggable: false, resizable: false,
        datafield: '', columntype: 'number', width: '5%', cellsalign: 'center', align: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 10px;">' + (value + 1) + '</div>';
        }
      },
      { text: 'Sku', datafield: 'Sku', cellsalign: 'center', align: 'center', editable: false, width: '47%' },
      { text: 'Cantidad Ingresada', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '20%' },
      {
        text: 'Cantidad', datafield: 'CantidadIngresada', cellsalign: 'center', align: 'center', filterable: false, width: '15%',
        validation: (cell: any, value: any): any => {
          let valor = String(value);
          let valid: boolean;
          if (valor.match("^[0-9]*$")) {
            valid = false;
          } else {
            valid = true
          }

          if (value == "") value = null;

          if (value < 0) {
            return { message: 'Cantidad no puede ser menor o igual a cero', result: false };
          } else if (value > this.Cantidad) {
            return { message: 'Cantidad no puede ser mayor a la cantidad', result: false };
          } else if (value == 0) {
            return { message: 'Cantidad no puede ser cero', result: false };
          } else if (valid == true) {
            return { message: 'Solo puede ingresar números', result: false };
          } else if (value == null) {
            return { result: true };
          }
          return true;
        }
      },
      { text: "Agregar", datafield: "AgregarClick", cellsalign: 'center', align: "center", filterable: false, editable: false, width: '13%', cellsrenderer: this.AgregarCellRenderer }
    ];

}

enum GridDividirSkuColumns {
  AgregarClick = 'AgregarClick'
}
