import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';


@Component({
  selector: 'app-grid-gestiones-mensual-sku',
  templateUrl: './grid-gestiones-mensual-sku.component.html',
  styleUrls: ['./grid-gestiones-mensual-sku.component.css']
})
export class GridGestionesMensualSkuComponent implements OnInit, AfterViewInit {

  constructor() { }
  @ViewChild('myGridGestionesMensualSku', { static: false }) myGridGestionesMensualSku: jqxGridComponent;
  //@Input() gestiones: Array<NewGestionesII> = [];

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    //this.source.localdata = this.gestiones;
    //this.dataAdapter.dataBind();
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Mes', type: 'string' },
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'CodigoGestion', type: 'string' },
        { name: 'Semana', type: 'number' },
        { name: 'SemanaEtaModificada', type: 'number' },
        { name: 'EtaModificada', type: 'date' },
        { name: 'SitioActual', map: 'SitioActual>Nombre', type: 'string' },
        { name: 'Unidad', type: 'number' },
      ],
      id: "CodigoGestion",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center', pinned: true,
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      { text: 'Mes', datafield: 'Mes', cellsalign: 'center', align: 'center', pinned: true, width: '15%' },
      //{ text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', pinned: true, width: '20%' },
      { text: 'Gestión', datafield: 'CodigoGestion', cellsalign: 'center', align: 'center', width: '10%' },
      //{ text: 'Semana', datafield: 'Semana', cellsalign: 'center', align: 'center', filterable: false, width: '5%' },
      { text: 'Semana ETA Proyectada', datafield: 'SemanaEtaModificada', cellsalign: 'center', align: 'center', filterable: false, width: '15%' },
      { text: 'ETA Proyectada', datafield: 'EtaModificada', cellsalign: 'center', align: 'center', width: '12%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      { text: 'Sitio', datafield: 'SitioActual', cellsalign: 'left', align: 'center', filterable: false, width: '33%' },
      {
        text: 'Unidades', datafield: 'Unidad', cellsalign: 'center', align: 'center', filterable: false, width: '10%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates) => {
          if (aggregates.sum == undefined) {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + "0" + '</div>';
            return renderstring;
          } else {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum  + '</div>';
            return renderstring;
          }         
        }
      }
    ];
}

export interface NewGestionesII {
  Mes: string;
  Nmes: number;
  Abierta: boolean;
  CodigoGestion: string;
  CodigoSku: string;
  Eta: Date;
  EtaModificada: Date;
  GestionSeguimientoId: string;
  ProveedorNombre: string;
  Semana: number;
  SemanaEtaModificada: number;
  SitioActual: { Id: string; Nombre: string };
  Unidad: number;
}
