import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { JqxGridModule } from '../../ng-modules/jqx-grid/jqx-grid.module';
import { JqxChartModule } from '../../ng-modules/jqx-chart/jqx-chart.module';
import { ModificacionesForecastComponent } from './modificaciones-forecast/modificaciones-forecast.component';
import { GridModificacionesForecastComponent } from './grid-modificaciones-forecast/grid-modificaciones-forecast.component';
import { GridGestionesComponent } from './grid-gestiones/grid-gestiones.component';
import { GridRequerimientosAfectadosComponent } from './grid-requerimientos-afectados/grid-requerimientos-afectados.component';
import { GraficoComponent } from './grafico/grafico.component';
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
  declarations: [ModificacionesForecastComponent, GridModificacionesForecastComponent, GridGestionesComponent, GridRequerimientosAfectadosComponent, GraficoComponent],
  imports: [
    CommonModule,
    FormsModule,
    JqxGridModule,
    JqxChartModule,
    NgApexchartsModule
  ]
})
export class ModificacionesForecastModule { }
