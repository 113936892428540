import { Component, OnInit, ViewChild, Inject, Output, EventEmitter } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { GridPlantillasCreadasVm, RespuestaUpiEliminarPlantilla } from 'src/app/objects/upi-object';
import { DialogService, CallHttp } from 'vesta';
import { IUserAuth, USER_AUTH } from 'vesta-authentication';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-grid-plantillas',
  templateUrl: './grid-plantillas.component.html',
  styleUrls: ['./grid-plantillas.component.css']
})

export class GridPlantillasComponent implements OnInit {
  constructor(private dialogService: DialogService, private CallHttp: CallHttp, @Inject(USER_AUTH) private user: IUserAuth) { }
  @ViewChild('myGridPlantillas', { static: false }) myGridPlantillas: jqxGridComponent;
  @Output() EditarPlantilla = new EventEmitter();
  @Output() reloadEliminar = new EventEmitter();
  @Output() UsarPlantilla = new EventEmitter();

  ngOnInit() {
  }

  CellClick(event: any) {
    let rowData = event.args.row.bounddata;
    let dataField = event.args.datafield;

    if (dataField == GridPlantillasColumns.EditarPlantillaClick) this.EditarPlantillaClick(rowData);
    else if (dataField == GridPlantillasColumns.EliminarPlantillaClick) this.EliminarPlantillaClick(rowData);
    else if (dataField == GridPlantillasColumns.UsarPlantillaClick) this.UsarPlantillaClick(rowData);
  }

  ///Ver Detalle de pago
  EditarPlantillaCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="26" width="26" src="../../../../assets/icons/edit.png"></div>'
  }

  EditarPlantillaClick(rowData: GridPlantillasCreadasVm) {
    this.EditarPlantilla.emit(rowData);
  }

  EliminarPlantillaCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="26" width="26" src="../../../../assets/icons/delete.png"></div>'
  }

  EliminarPlantillaClick(rowData: GridPlantillasCreadasVm) {
    this.dialogService.ShowConfirm("¿Esta seguro de eliminar la plantilla?", "")
      .subscribe(confirm => {
        if (confirm) {
          let obj = {
            plantillaUPIId: rowData.Id,
            modifiedBy: this.user.Id
          }

          this.CallHttp.httpPost<RespuestaUpiEliminarPlantilla>(environment.webServices.ppm + "Mants/PlantillasUPI/Home/DeletePlantillaUPI", obj)
            .subscribe(response => {
              if (response.IsValid) {
                this.reloadEliminar.emit();
                this.dialogService.NotificacionSucess(response.Message);
              } else {
                this.dialogService.NotificacionWarning(response.Message);
              }
            }, error => {
              this.dialogService.NotificacionAlert(error);
            });
        }
      });
  }

  usarPlantillaCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.3rem;"><img style="cursor: pointer" height="26" width="26" src="../../../../assets/icons/send.png"></div>'
  }

  UsarPlantillaClick(rowData: GridPlantillasCreadasVm) {
    this.UsarPlantilla.emit(rowData);
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'CantidadMeses', type: 'number' },
        { name: 'CreatedBy', type: 'string' },
        { name: 'CreatedDate', type: 'date' },
        { name: 'DescripcionPlantilla', type: 'string' },
        { name: 'FechaFin', type: 'date' },
        { name: 'FechaInicio', type: 'date' },
        { name: 'GrupoMaterial', type: 'array' },
        { name: 'Id', type: 'string' },
        { name: 'Skus', type: 'array' },
        { name: 'TipoMaterial', type: 'array' },        
        { name: 'Planta', type: 'string' },
        { name: 'Plantas', type: 'array' }        
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Plantilla', datafield: 'DescripcionPlantilla', cellsalign: 'center', align: 'center', editable: false, width: '30%' },
      { text: 'Planta', datafield: 'Planta', cellsalign: 'center', align: 'center', editable: false, width: '30%' },
      { text: 'Cantidad De Meses', datafield: 'CantidadMeses', cellsalign: 'center', align: 'center', filterable: false, editable: false, width: '10%' },
      {
        text: "Editar", cellsalign: 'center', align: "center", datafield: "EditarPlantillaClick", filterable: false, editable: false, width: '10%',
        cellsrenderer: this.EditarPlantillaCellRenderer
      },
      {
        text: "Eliminar", cellsalign: 'center', align: "center", datafield: "EliminarPlantillaClick", filterable: false, editable: false, width: '10%',
        cellsrenderer: this.EliminarPlantillaCellRenderer
      },
      {
        text: "", cellsalign: 'center', align: "center", datafield: "UsarPlantillaClick", filterable: false, editable: false, width: '10%',
        cellsrenderer: this.usarPlantillaCellRenderer
      }
    ];

}

enum GridPlantillasColumns {
  EditarPlantillaClick = 'EditarPlantillaClick',
  EliminarPlantillaClick = 'EliminarPlantillaClick',
  UsarPlantillaClick = 'UsarPlantillaClick'
}
